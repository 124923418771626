
.navbar-brand{
    font-size: large;
    color: black;
    font-weight: bold;
}
.nav-item{
    font-size: large;
}

.nav-item a{
    transition: 0.3s;
    color: rgb(9, 1, 1);
}

.nav-item a:hover{
    color: rgb(244, 7, 7) !important
}

.btn{
    font-size: large;
    border-radius: 50px;
    transition: 0.3s;
}

.p_hero{
    text-align: left;
}