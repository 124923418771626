.about_hero{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_hero , h1{
    font-size: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_hero , h1 , span{
    color:red
}

.btn-group{
    font-size:large;
    display: flex;
    justify-content:center;
    align-items:center;
}


.btn-group a{
    color: black;
}
.btn-group a:hover{
    color: red;
}
