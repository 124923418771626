*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.about_hero{
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_hero , h1{
  font-size: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_hero , h1 , span{
  color:red
}