.about_hero{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_hero , h1{
    font-size: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_hero , h1 , span{
    color:red
}


.para_about{
    font-size:medium;
    display: flex;
    justify-content: center;
    align-items: center;
}

.book_table{
  background-color: white;
  width: 50%;
  height: 15%;
  border: 1px solid ;
  text-align: center;
  font-size: medium;
  font-weight: bold;
  color: rgb(247, 246, 246);
  box-sizing: border-box;
  box-shadow: 0px 2px 25px rgba(247, 245, 245, 0.8);
  position: relative;
  padding-top: 20px;
}

.uk-card{
    border: 1px solid none;
    box-sizing: border-box !important;
    box-shadow: 0px 2px 2px 10px;
    transform: all .2s ease-in-out !important;
    transform: scale((1.1)) !important;
}

